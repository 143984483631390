import { PropsWithChildren } from "react";
import { clsx } from "clsx";
import { LanguageContextProvider } from "~/hooks/useLanguageFromContext";

type Props = {
  disableGrid?: boolean;
};

export function Page({
  children,
  disableGrid = false,
}: PropsWithChildren<Props>) {
  return (
    <LanguageContextProvider>
      <main
        className={clsx("mx-auto", "w-[92.5%]", {
          "grid grid-cols-12 gap-[30px]": !disableGrid,
        })}
      >
        {children}
      </main>
    </LanguageContextProvider>
  );
}
